import React, { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import help from '../../../src/help.png';
import convertDate from "../Data/convertDate";
import { useNavigate } from 'react-router-dom';
const moment = require('moment');



const Home = () =>  {
    
  
    
    //const = serialNoInputRef;
    const[serialNoError, setSerialNoError] = useState('');
    //const handleRegistration = (data) => console.log(data);
    const { register, handleSubmit } = useForm();
    const [returnWarranty , setWarranty ] = useState('');
const [customer , setCustomer, validationErrors ] = useState({ 
       ProductName:'',
       ProductSerialNumber:'',
       DateOfPurchase:'',
       RetailerName:'',
       HowdidYouHear:'',
       IndustryType:'',
       Title:'',
       FirstName:'',
       LastName:'',
       JobTitle:'',
       EstablishmentName:'',
       Email:'',
       Phone:'',
       StreetAddress: '',
       AddressLine2: '',
       City: '',
       State: '',
       PostalCode: '',
       Country: ''});
   

    const warrantyMsg = useRef(null);

    const setInput = (e) =>{

        //e.preventDefault();

        let {name, value} = e.target;
    
        
        value = value.replace(/['‘’"“”]/g, '');
        
        if(value.length === 0){

            e.preventDefault();
           

          
           /* setCustomer(prevState => ({
    
              ...prevState,
    
              [name]: parseInt(value)
    
            }))
            */
    
         }else{

            
            setCustomer(prevState => ({

                ...prevState,
      
                [name]: value
      
              }))
            }

            
         
        }
        const fetchData = async() => {
            setSerialNoError('');
            try {
              const newData = await fetch('/customers',{
                method: 'POST',
                headers:{
                  'Content-Type': 'application/json',
                  'Accept': 'application/json'
                },
                body: JSON.stringify({
                  name: customer.ProductSerialNumber
                })
              }).then(res=>res.json())
          
              if(newData[0].ProductSerialNumber === customer.ProductSerialNumber) {
                setSerialNoError("already registered");
              } else {
                setWarranty(newData[0]);
                setSerialNoError(""); // clear the error state
              }
            } catch(error) {
              console.log(error);
            }          
          }
          
    

          const createData = async(e) => {    
            
            if(serialNoError === '') {
              try {
                const newCust = await fetch('/register-warranty',{
                  method: 'POST',
                  headers:{
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                  },
                  body: JSON.stringify({
                     ...customer
                  })
                }).then(res=>res.json())
          
                setWarranty(newCust[0]);
                alert("Warranty Submitted Successfully");
              } catch(error) {
                console.log(error);
              }
            } else {
              e.preventDefault();
              document.getElementById("ProductSerialNumber").focus();
              //serialNoError.current.focus();
              alert("Warranty is already registered");
              alert(serialNoError);
            }
          }
          
        
          
          
    const getSerialDate = async () =>{

        try{
        const checkDate = await fetch('/serial-date',{
            method: 'POST',
            headers:{
    
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            
            body: JSON.stringify({
    
                name:customer.ProductSerialNumber
    
            })
    
    
            }).then(res=>res.json())
    

                const customerPurchase = convertDate(customer.DateOfPurchase);

                const bulkOrderCheck = convertDate(checkDate[0].DateOfPurchase);

                var diff = Math.floor((customerPurchase - bulkOrderCheck ) / 86400000);


                const num = 90;

            if(diff >= num){

                
               
                warrantyMsg.current.textContent = "Warranty Registration is over 90 Days, 1 Year Warranty applicable";
                
            }else{

                warrantyMsg.current.textContent = "Warranty Registration is within 90 Days, 5 Year Warranty guarantee";
            
            }

        }catch(error){

            console.log(error);
        }

      
    }

        return (
               
                                <>
                                    
                                    <h3>Warranty Registration</h3>

                                    <p className="important_text">All Purchases made must be registered within 90 days of delivery<br/>For free warranty</p>

                                    <div class="mainWarrantyReg">
                                    <form onSubmit={createData}>
                                
                                    
                                        <label>Product Purchased *</label><br />

                                        <select onChange={setInput} id="ProductName" name="ProductName" className="un" required="required">
                                            <option value="">--Select Product--</option>
                                            <option value="55 GTOUCH 4K+ (Ruby)">55" GTOUCH 4K+ (Ruby)</option>
                                            <option value="65 GTOUCH SLIM STND (Sapphire)">65" GTOUCH SLIM STND (Sapphire)</option>
                                            <option value="65GT 4K 848 GTOUCH SCREEN (Ruby)">65GT 4K 848 GTOUCH SCREEN (Ruby)</option>
                                            <option value="65GT 4K TOUCH SCREEN (Deluxe)">65GT 4K TOUCH SCREEN (Deluxe)</option>
                                            <option value="65GT 4K+ (4K Plus)">65GT 4K+ (4K Plus)</option>
                                            <option value="65GT 2K">65GT 2K</option>
                                            <option value="75GT 4K GTOUCH SCREEN (Deluxe)">75GT 4K GTOUCH SCREEN (Deluxe)</option>
                                            <option value="75 GT 4K 848 GTOUCH SCREEN (Ruby)">75 GT 4K 848 GTOUCH SCREEN (Ruby)</option>
                                            <option value="75 GTOUCH 4K+ (4K Plus)">75 GTOUCH 4K+ (4K Plus)</option>
                                            <option value="86 GT 4K 848 GTOUCH SCREEN (Ruby)">86 GT 4K 848 GTOUCH SCREEN (Ruby)</option>
                                            <option value="86 GT 4K (Deluxe)">86 GT 4K (Deluxe)</option>
                                            <option value="86 GT 4K+ (4K Plus)">86 GT 4K+ (4K Plus)</option>
                                            <option value="80GT FHD DELUXE">80GT FHD DELUXE</option>
                                            <option value="32 TOUCH TABLE">32" TOUCH TABLE</option>
                                            <option value="42 TOUCH TABLE">42" TOUCH TABLE</option>
                                            <option value="42 Hi-Lo TOUCH TABLE">42" Hi-Lo TOUCH TABLE</option>
                                            <option value="65GT-848-PANTHER (Emerald)">65GT-848-PANTHER (Emerald)</option>
                                            <option value="75GT-848-PANTHER (Emerald)">75GT-848-PANTHER (Emerald)</option>
                                            <option value="86GT-848-PANTHER (Emerald)">86GT-848-PANTHER (Emerald)</option>
                                            <option value="G-Touch 65 PX5L4K+ (Opal)">G-Touch 65 PX5L4K+ (Opal)</option>
                                            <option value="G-Touch 75 PX5L4K+ (Opal)">G-Touch 75 PX5L4K+ (Opal)</option>
                                            <option value="G-Touch 86 PX5L4K+ (Opal)"> G-Touch 86 PX5L4K+ (Opal)</option>
                                            <option value="GV150">GV150</option>
                                            <option value="GV50 UHD3">GV50 UHD3</option>
                                            <option value="GV50UHD 5G">GV50UHD 5G</option>
                                            <option value="GV4200HD">GV4200HD</option>
                                            <option value="GV4200W">GV4200W</option>
                                            <option value="GV5100HD">GV5100HD</option>
                                            <option value="GV91004K">GV91004K</option>
                                            <option value="GVE100W">GVE100W</option>
                                            <option value="GVE200W">GVE200W</option>
                                            <option value="GV MULTISERVER">GV MULTISERVER</option>
                                            <option value="OPS UNIT 128SSD">OPS UNIT 128SSD</option>
                                            <option value="OPS UNIT 240SSD">OPS UNIT 240SSD</option>
                                            <option value="GENEE REGISTRAR">GENEE REGISTRAR</option>
                                        </select>
                                        
                                        <br/>
                                       
                                    
                                        <label>Serial Number(s)*<span className="important_text"> if more than one please use commas</span></label><br />
                                        <input onBlur={()=>fetchData()} onChange={setInput} type="text" id="ProductSerialNumber" name="ProductSerialNumber" className="un" defaultValue="" minLength="5" required="required" />
                                        <br/>
                                        <span id="serialNoError">{serialNoError}</span>
                                        
                                        <br/>
                                        <label>Date of Purchase*</label><br />
                                        <input onBlur={()=>getSerialDate()} onChange={setInput} type="date" id="datepicker" name="DateOfPurchase" className="un" defaultValue="" required="required"/>
                                        <div id="warrantyMsg" ref={warrantyMsg}></div>
                                        <br/>
                                    
                                        <label>Organisation purchased from*</label><br />
                                        <input onChange={setInput} type="text" id="RetailerName" name="RetailerName" className="un" defaultValue="" required="required"/>
                                        
                                  
                                        <br/>
                                        <label>How did you hear about Genee Products?</label><br />
                                        <input onChange={setInput} type="text" id="HowdidYouHear" name="HowdidYouHear" className="un" defaultValue="" required="required" />
                                        
                                        <br/>
                                  
                                        <label>Industry Type (e.g. school)*</label>
                                        <br/>
                                        <select onChange={setInput} id="IndustryType" name="IndustryType" className="un" required="required">
                                       
                                            <option value="">--Select IndustryType--</option>
                                            <option value="Education">Education</option>
                                            <option value="Public Sector">Public Sector</option>
                                            <option value="Commercial Organisation">Commercial Organisation</option>
                                        </select>
                                        
                                        <br/>
                                    
                                        <label>Title*</label>
                                        <br/>
                                        <select onChange={setInput} id="Title" name="Title" className="un" required="required">
                                            <option value= "">--Select Title--</option>
                                            <option value= ""> Prefer not to say</option>
                                            <option value="Mr.">Mr.</option>
                                            <option value="Mrs.">Mrs.</option>
                                            <option value="Ms.">Ms.</option>
                                        </select>
                                       
                                        <br/>
                                    
                                        <label>First Name*</label><br />
                                        <input onChange={setInput} type="text" id="FirstName" name="FirstName" className="un" defaultValue="" required="required"/>
                                      
                                        <br/>
                                    
                                        <label>Last Name*</label><br />
                                        <input onChange={setInput} type="text" id="LastName" name="LastName" className="un" defaultValue="" required="required" />
                                        <br/>
                                   
                                    
                                        <label>Job Title*</label><br />
                                        <input onChange={setInput} type="text" id="JobTitle"  name="JobTitle" className="un" defaultValue="" required="required"/>
                                        
                                    
                                        <br/>
                                        <label>Establishment Name*</label><br />
                                        <input onChange={setInput} type="text" id="EstablishmentName" name="EstablishmentName" className="un" defaultValue="" required="required"/>
                                        
                                        <br/>
                                    
                                        <label >Email address*</label><br />
                                        <input  type="email" onChange={setInput} id="Email" name="Email"  className="un" defaultValue="" required="required"/>
                                        
                                    
                                        <br/>
                                        <label>Phone Number*</label><br />
                                        <input onChange={setInput} type="text" id="Phone" name="Phone" className="un" defaultValue="" required="required" />
                                        
                                    
                                        <br/>
                                        <label>Street Address*</label><br />
                                        <input onChange={setInput} type="text" id="StreetAddress" name="StreetAddress" className="un" defaultValue="" required="required" />
                                       
                                        <br/>
                                    
                                        <label>Address Line 2</label><br />
                                        <input onChange={setInput} type="text" id="AddressLine2" name="AddressLine2" className="un" defaultValue="" />
                                 
                                        <br/>
                                        <label>City*</label><br />
                                        <input onChange={setInput} type="text" id="City" name="City" className="un" defaultValue="" required="required"/>
                      
                                        <br/>
                                   
                                        <label>County*</label><br />
                                        <input onChange={setInput} type="text" id="State" name="State" className="un" defaultValue="" required="required" />
                                        <br/>
                                    
                                        <label>Postcode*</label><br />
                                        <input onChange={setInput} type="text" id="PostalCode" name="PostalCode" className="un" defaultValue=""  required="required"/>
                                        
                                        <br/>
                                    
                                        <label>Country*</label>
                                        <br/>
                                        <select onChange={setInput} id="Country" name="Country" className="un" required="required">
                                            <option value="">--Select Country--</option>
                                            <option value="Afghanistan">Afghanistan</option>
                                            <option value="Albania">Albania</option>
                                            <option value="Algeria">Algeria</option>
                                            <option value="American Samoa">American Samoa</option>
                                            <option value="Andorra">Andorra</option>
                                            <option value="Angola">Angola</option>
                                            <option value="Anguilla">Anguilla</option>
                                            <option value="Antarctica">Antarctica</option>
                                            <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                                            <option value="Argentina">Argentina</option>
                                            <option value="Armenia">Armenia</option>
                                            <option value="Aruba">Aruba</option>
                                            <option value="Australia">Australia</option>
                                            <option value="Austria">Austria</option>
                                            <option value="Azerbaijan">Azerbaijan</option>
                                            <option value="Bahamas">Bahamas</option>
                                            <option value="Bahrain">Bahrain</option>
                                            <option value="Bangladesh">Bangladesh</option>
                                            <option value="Barbados">Barbados</option>
                                            <option value="Belarus">Belarus</option>
                                            <option value="Belgium">Belgium</option>
                                            <option value="Belize">Belize</option>
                                            <option value="Benin">Benin</option>
                                            <option value="Bermuda">Bermuda</option>
                                            <option value="Bhutan">Bhutan</option>
                                            <option value="Bolivia">Bolivia</option>
                                            <option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
                                            <option value="Botswana">Botswana</option>
                                            <option value="Bouvet Island">Bouvet Island</option>
                                            <option value="Brazil">Brazil</option>
                                            <option value="British Indian Ocean Territory">British Indian Ocean Territory</option>
                                            <option value=">Brunei Darussalam">Brunei Darussalam</option>
                                            <option value="Bulgaria">Bulgaria</option>
                                            <option value="Burkina Faso">Burkina Faso</option>
                                            <option value="Burundi">Burundi</option>
                                            <option value="Cambodia">Cambodia</option>
                                            <option value="Cameroon">Cameroon</option>
                                            <option value="Canada">Canada</option>
                                            <option value="Cape Verde">Cape Verde</option>
                                            <option value="Cayman Islands40">Cayman Islands</option>
                                            <option value="Central African Republic">Central African Republic</option>
                                            <option value="Chad">Chad</option>
                                            <option value="Chile">Chile</option>
                                            <option value="China">China</option>
                                            <option value="Christmas Island">Christmas Island</option>
                                            <option value="Cocos (Keeling) Islands">Cocos (Keeling) Islands</option>
                                            <option value="Colombia">Colombia</option>
                                            <option value="Comoros">Comoros</option>
                                            <option value="Congo">Congo</option>
                                            <option value="Congo, the Democratic Republic of the">Congo, the Democratic Republic of the</option>
                                            <option value="Cook Islands">Cook Islands</option>
                                            <option value="Costa Rica">Costa Rica</option>
                                            <option value="Cote D'Ivoire">Cote D'Ivoire</option>
                                            <option value="Croatia">Croatia</option>
                                            <option value="Cuba">Cuba</option>
                                            <option value="Cyprus">Cyprus</option>
                                            <option value="Czech Republic">Czech Republic</option>
                                            <option value="Denmark">Denmark</option>
                                            <option value="Djibouti">Djibouti</option>
                                            <option value="Dominica">Dominica</option>
                                            <option value="61">Dominican Republic</option>
                                            <option value="Ecuador">Ecuador</option>
                                            <option value="Egypt">Egypt</option>
                                            <option value="El Salvador">El Salvador</option>
                                            <option value="Equatorial Guinea">Equatorial Guinea</option>
                                            <option value="Eritrea">Eritrea</option>
                                            <option value="Estonia">Estonia</option>
                                            <option value="Ethiopia">Ethiopia</option>
                                            <option value="Falkland Islands (Malvinas)">Falkland Islands (Malvinas)</option>
                                            <option value="Faroe Islands">Faroe Islands</option>
                                            <option value="Fiji">Fiji</option>
                                            <option value="Finland">Finland</option>
                                            <option value="France">France</option>
                                            <option value="French Guiana">French Guiana</option>
                                            <option value="French Polynesia">French Polynesia</option>
                                            <option value="French Southern Territories6">French Southern Territories</option>
                                            <option value="Gabon">Gabon</option>
                                            <option value="Gambia">Gambia</option>
                                            <option value="Georgia">Georgia</option>
                                            <option value="Germany">Germany</option>
                                            <option value="Ghana">Ghana</option>
                                            <option value="Gibraltar">Gibraltar</option>
                                            <option value="Greece">Greece</option>
                                            <option value="Greenland">Greenland</option>
                                            <option value="Grenada">Grenada</option>
                                            <option value="Guadeloupe">Guadeloupe</option>
                                            <option value="Guam">Guam</option>
                                            <option value="Guatemala">Guatemala</option>
                                            <option value="Guinea">Guinea</option>
                                            <option value="Guinea-Bissau">Guinea-Bissau</option>
                                            <option value="Guyana">Guyana</option>
                                            <option value="Haiti">Haiti</option>
                                            <option value="Heard Island and Mcdonald Islands">Heard Island and Mcdonald Islands</option>
                                            <option value="Holy See (Vatican City State)">Holy See (Vatican City State)</option>
                                            <option value="Honduras">Honduras</option>
                                            <option value="Hong Kong">Hong Kong</option>
                                            <option value="Hungary">Hungary</option>
                                            <option value="Iceland">Iceland</option>
                                            <option value="India">India</option>
                                            <option value="Indonesia">Indonesia</option>
                                            <option value="Iran, Islamic Republic of">Iran, Islamic Republic of</option>
                                            <option value="Iraq">Iraq</option>
                                            <option value="Ireland">Ireland</option>
                                            <option value="Israel">Israel</option>
                                            <option value="Italy">Italy</option>
                                            <option value="Jamaica">Jamaica</option>
                                            <option value="Japan">Japan</option>
                                            <option value="108">Jordan</option>
                                            <option value="Kazakhstan">Kazakhstan</option>
                                            <option value="Kenya">Kenya</option>
                                            <option value="Kiribati">Kiribati</option>
                                            <option value="Korea, Democratic People's Republic of">Korea, Democratic People's Republic of</option>
                                            <option value="Korea, Republic of">Korea, Republic of</option>
                                            <option value="Kuwait">Kuwait</option>
                                            <option value="Kyrgyzstan">Kyrgyzstan</option>
                                            <option value="Lao People's Democratic Republic">Lao People's Democratic Republic</option>
                                            <option value="Latvia">Latvia</option>
                                            <option value="Lebanon">Lebanon</option>
                                            <option value="Lesotho">Lesotho</option>
                                            <option value="Liberia">Liberia</option>
                                            <option value="Libyan Arab Jamahiriya">Libyan Arab Jamahiriya</option>
                                            <option value="Liechtenstein">Liechtenstein</option>
                                            <option value="Lithuania">Lithuania</option>
                                            <option value="Luxembourg">Luxembourg</option>
                                            <option value="Macao">Macao</option>
                                            <option value="Macedonia, the Former Yugoslav Republic of">Macedonia, the Former Yugoslav Republic of</option>
                                            <option value="Madagascar">Madagascar</option>
                                            <option value="Malawi">Malawi</option>
                                            <option value="Malaysia">Malaysia</option>
                                            <option value="Maldives">Maldives</option>
                                            <option value="Mali">Mali</option>
                                            <option value="Malta">Malta</option>
                                            <option value="Marshall Islands">Marshall Islands</option>
                                            <option value="Martinique">Martinique</option>
                                            <option value="Mauritania">Mauritania</option>
                                            <option value="Mauritius">Mauritius</option>
                                            <option value="Mayotte">Mayotte</option>
                                            <option value="Mexico">Mexico</option>
                                            <option value="Micronesia, Federated States of">Micronesia, Federated States of</option>
                                            <option value="Moldova, Republic of">Moldova, Republic of</option>
                                            <option value="Monaco">Monaco</option>
                                            <option value="Mongolia">Mongolia</option>
                                            <option value="Montserrat">Montserrat</option>
                                            <option value="Morocco">Morocco</option>
                                            <option value="Mozambique">Mozambique</option>
                                            <option value="Myanmar">Myanmar</option>
                                            <option value="Namibia">Namibia</option>
                                            <option value="Nauru">Nauru</option>
                                            <option value="Nepal">Nepal</option>
                                            <option value="Netherlands">Netherlands</option>
                                            <option value="Netherlands Antilles">Netherlands Antilles</option>
                                            <option value="New Caledonia">New Caledonia</option>
                                            <option value="New Zealand">New Zealand</option>
                                            <option value="Nicaragua">Nicaragua</option>
                                            <option value="Niger">Niger</option>
                                            <option value="Nigeria">Nigeria</option>
                                            <option value="Niue">Niue</option>
                                            <option value="Norfolk Island">Norfolk Island</option>
                                            <option value="Northern Mariana Islands">Northern Mariana Islands</option>
                                            <option value="Norway">Norway</option>
                                            <option value="Oman">Oman</option>
                                            <option value="Pakistan">Pakistan</option>
                                            <option value="Palau">Palau</option>
                                            <option value="Palestinian Territory, Occupied">Palestinian Territory, Occupied</option>
                                            <option value="Panama">Panama</option>
                                            <option value="Papua New Guinea">Papua New Guinea</option>
                                            <option value="Paraguay">Paraguay</option>
                                            <option value="Peru">Peru</option>
                                            <option value="Philippines">Philippines</option>
                                            <option value="Pitcairn">Pitcairn</option>
                                            <option value="Poland">Poland</option>
                                            <option value="Portugal">Portugal</option>
                                            <option value="Puerto Rico">Puerto Rico</option>
                                            <option value="Qatar">Qatar</option>
                                            <option value="Reunion">Reunion</option>
                                            <option value="Romania">Romania</option>
                                            <option value="Russian Federation">Russian Federation</option>
                                            <option value="Rwanda">Rwanda</option>
                                            <option value="Saint Helena">Saint Helena</option>
                                            <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                                            <option value="Saint Lucia">Saint Lucia</option>
                                            <option value="Saint Pierre and Miquelon">Saint Pierre and Miquelon</option>
                                            <option value="Saint Vincent and the Grenadines">Saint Vincent and the Grenadines</option>
                                            <option value="Samoa">Samoa</option>
                                            <option value="San Marino">San Marino</option>
                                            <option value="Sao Tome and Principe">Sao Tome and Principe</option>
                                            <option value="Saudi Arabia">Saudi Arabia</option>
                                            <option value="Senegal">Senegal</option>
                                            <option value="Serbia and Montenegro">Serbia and Montenegro</option>
                                            <option value="Seychelles">Seychelles</option>
                                            <option value="Sierra Leone">Sierra Leone</option>
                                            <option value="Singapore">Singapore</option>
                                            <option value="Slovakia">Slovakia</option>
                                            <option value="Slovenia">Slovenia</option>
                                            <option value="Solomon Islands">Solomon Islands</option>
                                            <option value="Somalia">Somalia</option>
                                            <option value="South Africa">South Africa</option>
                                            <option value="South Georgia and the South Sandwich Islands">South Georgia and the South Sandwich Islands</option>
                                            <option value="Spain">Spain</option>
                                            <option value="Sri Lanka">Sri Lanka</option>
                                            <option value="Sudan">Sudan</option>
                                            <option value="Suriname">Suriname</option>
                                            <option value="Svalbard and Jan Mayen">Svalbard and Jan Mayen</option>
                                            <option value="Swaziland">Swaziland</option>
                                            <option value="Sweden">Sweden</option>
                                            <option value="Switzerland">Switzerland</option>
                                            <option value="Syrian Arab Republic">Syrian Arab Republic</option>
                                            <option value="Taiwan, Province of China">Taiwan, Province of China</option>
                                            <option value="Tajikistan">Tajikistan</option>
                                            <option value="Tanzania, United Republic of">Tanzania, United Republic of</option>
                                            <option value="Thailand">Thailand</option>
                                            <option value="Timor-Leste">Timor-Leste</option>
                                            <option value="Togo">Togo</option>
                                            <option value="Tokelau">Tokelau</option>
                                            <option value="Tonga">Tonga</option>
                                            <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                                            <option value="Tunisia">Tunisia</option>
                                            <option value="Turkey">Turkey</option>
                                            <option value="Turkmenistan">Turkmenistan</option>
                                            <option value="Turks and Caicos Islands">Turks and Caicos Islands</option>
                                            <option value="Tuvalu">Tuvalu</option>
                                            <option value="Uganda">Uganda</option>
                                            <option value="Ukraine">Ukraine</option>
                                            <option value="United Arab Emirates">United Arab Emirates</option>
                                            <option value="United Kingdom">United Kingdom</option>
                                            <option value="United States">United States</option>
                                            <option value="United States Minor Outlying Islands">United States Minor Outlying Islands</option>
                                            <option value="Uruguay">Uruguay</option>
                                            <option value="Uzbekistan">Uzbekistan</option>
                                            <option value="Vanuatu">Vanuatu</option>
                                            <option value="Venezuela">Venezuela</option>
                                            <option value="Vietnam">Vietnam</option>
                                            <option value="Virgin Islands, British">Virgin Islands, British</option>
                                            <option value="Virgin Islands, U.s.">Virgin Islands, U.s.</option>
                                            <option value="Wallis and Futuna">Wallis and Futuna</option>
                                            <option value="Western Sahara">Western Sahara</option>
                                            <option value="Yemen">Yemen</option>
                                            <option value="Zambia">Zambia</option>
                                            <option value="Zimbabwe">Zimbabwe</option>
                                        </select>
                                        <br/>
                                        <button className="submit" type="submit">Submit</button>
                                        </form>
                                    </div>
                                    <div className="helpdesk">
                                        <span className="spanLeft">
                                            
                                        </span>
                                        
                                        <p>Sales +44 (0)1902 390862 (OPTION 1)</p>
                                        <p>technical@genee-group.com</p>
                                    </div>
                                    
                                    </>

                                    
                                    
                                   
                                    

                               
                         
                
        
        );
    };

export default Home;
