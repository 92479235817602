import React, { useState } from 'react';

export const AuthContext = React.createContext({
  isAuthenticated: false,
  setIsAuthenticated: () => {},
});

export const AuthProvider = ({ children }) => {
  
  const [timeoutId, setTimeoutId] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const handleSetIsAuthenticated = (value) => {
    setIsAuthenticated(value);
    console.log("isAuthenticated:", value);
    if (value) {
      // Set session timeout to 1 minute (for testing purposes)
      
      setSessionTimeout(60 * 60 * 1000);
    } else {
      // Cancel session timeout
      clearTimeout(timeoutId);
    }
  };
  
  const setSessionTimeout = (duration) => {
    // Clear previous timeout if it exists
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
  
    // Set new timeout
    const newTimeoutId = setTimeout(() => {
      console.log("Session timed out.");
      setIsAuthenticated(false);
    }, duration);
    setTimeoutId(newTimeoutId);
  };

  return (
    <AuthContext.Provider
      value={{ isAuthenticated, setIsAuthenticated: handleSetIsAuthenticated }}
    >
      {children}
    </AuthContext.Provider>
  );
};