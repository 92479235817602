import React, { useState,useEffect } from "react";
import { useForm } from "react-hook-form";
import help from '../../../src/help.png';
const moment = require('moment');

let convertDate = (usersDate) =>{


    const format1 = "YYYY-MM-DD";

    let rmvQuotes = usersDate.replace(/['"]+/g, '');

    
    let date = moment(rmvQuotes).format(format1);


    let update =  new Date(date);

   

    return update;

}

export default convertDate;