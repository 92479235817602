import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Create_Admin = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Convert password string to Uint8Array
    //const encoder = new TextEncoder();
    //const passwordUint8Array = encoder.encode(password);
    const response = await fetch('/new-admin', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ loginName: username, password: String(password) })
    });
    if (response.ok) {
      const data = await response.json();
      if (data && data.message) {
        // Authentication failed, display error message
        alert(data.message);
      } else {
        // User is authenticated, redirect to dashboard
        navigate('/');
      }
    } else {
      // Handle non-200 HTTP response
      alert('Server error: ' + response.statusText);
    }
  }
  

  return(
    <>
      <div className="main">
        <p className="sign" align="center">Create User</p>
        <form className="form1" onSubmit={handleSubmit}>
          <input className="un" type="text" align="center" placeholder="Username" value={username} onChange={e => setUsername(e.target.value)} />
          <input className="pass" type="password" align="center" placeholder="Password" value={password} onChange={e => setPassword(e.target.value)} />
          <button className="submit" type="submit" align="center">Sign in</button>
          <p className="forgot" align="center"><a href="#">Forgot Password?</a></p>
        </form>         
      </div>
    </>
  );
}

export default Create_Admin;
