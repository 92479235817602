import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './components/Home/Home';
import BulkUpload from './components/Bulk/BulkUpload';
import SerialChecker from './components/SerialChecker/SerialChecker';
import Login from './components/Dashboard/Login';
import Create_Admin from './components/Dashboard/Create_Admin';
import PrivateRoute from '../src/utils/PrivateRoute';
import { AuthProvider, AuthContext } from './utils/AuthContext';
import { Navigate, Outlet } from 'react-router-dom';
function AppRouter() {
  return (
 <div className="App">
 <BrowserRouter>
   <Routes>
     <Route path="/*" element={<Home />} />
     <Route path="/serial-checker" element={<SerialChecker />} />
     <Route path="/login" element={<Login />} />
     <Route path="/create_admin" element={<Create_Admin />} />
     <Route exact path='/bulk-upload' element={<PrivateRoute/>}>
        <Route exact path='/bulk-upload' element={<BulkUpload/>}/>
      </Route>
   </Routes>
 </BrowserRouter>
</div>
  );
}

export default AppRouter;
