import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

const SerialChecker = () => {

  let newData = [];
  const { register } = useForm();

  const [returnWarranty, setWarranty] = useState({
    ProductName: '0',
    ProductSerialNumber: '0',
    DateOfPurchase: '0',
    RetailerName: '0',
    HowdidYouHear: '0',
    IndustryType: '0',
    Title: '0',
    FirstName: '0',
    LastName: '0',
    JobTitle: '0',
    EstablishmentName: '0',
    Email: '0',
    Phone: '0',
    StreetAddress: '0',
    AddressLine2: '0',
    City: '0',
    State: '0',
    PostalCode: '0',
    Country: '0',
  });

  const [returnError, setError] = useState('');
  const [customer, setCustomer] = useState({});

  const setInput = (e) => {
    const { name, value } = e.target;

    if (name === 'ProductSerialNumber') {
      setCustomer((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  let fetchData = async () => {
    newData = await fetch("/customers", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        name: customer.ProductSerialNumber,
      }),
    })
      .then((res) => res.json())
      .catch((error) => {
        console.error("Error fetching data: " + error.message);
      });
  
    try {
      if (!newData.length) {
        // Handle empty response
        console.log("Empty response");
        console.log(newData);
      } else {
        console.log(newData);
        console.log(Array.isArray(newData));
        setWarranty({
          ProductName: newData[0].ProductName,
          ProductSerialNumber: newData[0].ProductSerialNumber,
          DateOfPurchase: newData[0].DateOfPurchase,
          RetailerName: newData[0].RetailerName,
          HowdidYouHear: newData[0].HowdidYouHear,
          IndustryType: newData[0].IndustryType,
          Title: newData[0].Title,
          FirstName: newData[0].FirstName,
          LastName: newData[0].LastName,
          JobTitle: newData[0].JobTitle,
          EstablishmentName: newData[0].EstablishmentName,
          Email: newData[0].Email,
          Phone: newData[0].Phone,
          StreetAddress: newData[0].StreetAddress,
          AddressLine2: newData[0].AddressLine2,
          City: newData[0].City,
          State: newData[0].State,
          PostalCode: newData[0].PostalCode,
          Country: newData[0].Country
        });
        
      }
    } catch (error) {
      console.error("Error: " + error.message);
    }
  };

  return (
    <>
    
    <h1>Welcome to Support</h1>
    <div className='serialChecker'>
    <h3>Identify your product</h3>
    <p>Enter a Serial Number</p>
    <input onChange={setInput} type="text" id="ProductSerialNumber" name="ProductSerialNumber" className='un'  />
    <button className='submit' onClick={()=>fetchData()}>Check</button>
    </div>
 

  <div class="flex-container-b">
  <div class="flex-item-left">
    <div id="leftDiv">
      
  
      </div>
</div>

<div class="flex-item-right">
   




</div>

        </div>

  
<article>
	<h1>Result</h1>
	<dl>
		<div>
			<dt>Product Name</dt>
			<dd>{returnWarranty.ProductName}</dd>
		</div>
		<div>
			<dt>Product Serial Number</dt>
			<dd>{returnWarranty.ProductSerialNumber}</dd>
		</div>
		<div>
			<dt>Date of Purchase</dt>
			<dd>{returnWarranty.DateOfPurchase}</dd>
		</div>
		<div>
			<dt>Retailer Name</dt>
			<dd>{returnWarranty.RetailerName}</dd>
		</div>
		<div>
			<dt>How did You Hear</dt>
			<dd>{returnWarranty.HowdidYouHear}</dd>
		</div>
    <div>
			<dt>Title</dt>
			<dd>{returnWarranty.Title}</dd>
		</div>

    <div>
			<dt>First Name</dt>
			<dd>{returnWarranty.FirstName}</dd>
		</div>
		<div>
			<dt>Last Name</dt>
			<dd>{returnWarranty.LastName}</dd>
		</div>
    <div>
			<dt>Job Title</dt>
			<dd>{returnWarranty.JobTitle}</dd>
		</div>

   

    <div>
			<dt>Establishment Name</dt>
			<dd>{returnWarranty.EstablishmentName}</dd>
		</div>
		<div>
			<dt>Email</dt>
			<dd>{returnWarranty.Email}</dd>
		</div>
    <div>
			<dt>Phone</dt>
			<dd>{returnWarranty.Phone}</dd>
		</div>


    <div>
			<dt>Street Address</dt>
			<dd>{returnWarranty.StreetAddress}</dd>
		</div>
		<div>
			<dt>Address Line 2</dt>
			<dd>{returnWarranty.AddressLine2}</dd>
		</div>
    <div>
			<dt>City</dt>
			<dd>{returnWarranty.City}</dd>
		</div>
    
          
    <div>
			<dt>County</dt>
			<dd>{returnWarranty.State}</dd>
		</div>
		<div>
			<dt>Postal Code</dt>
			<dd>{returnWarranty.PostalCode}</dd>
		</div>
    <div>
			<dt>Country</dt>
			<dd>{returnWarranty.Country}</dd>
		</div>      
          
	</dl>
</article>

</>
      
    

   
      
  );
};
export default SerialChecker;