import React, { useState, useContext } from 'react';
import {useNavigate} from 'react-router-dom';
import {PrivateRoute} from '../../utils/PrivateRoute';
import {AuthContext, AuthProvider} from '../../utils/AuthContext';


const Login = () => {
  
  const auth = useContext(AuthContext);
  const [Username, setUsername] = useState('');
  const [Password, setPassword] = useState('');

  const navigate = useNavigate();
  let responseData;
  // Access the AuthContext
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    // declare the variable here
    try {
      console.log(Username + Password);
      const response = await fetch('/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ Username: Username.trim(), Password: Password.trim() })
      });
      responseData = await response.json(); // assign value here
      if (response.ok && responseData.success) {
          console.log('correct login details');
          
          auth.setIsAuthenticated(true);
          navigate('/bulk-upload');
          console.log(auth.isAuthenticated);
          console.log('THIS THE AUTH LOG', auth);
          
  
          alert("Successfull Login");
      } else {
        alert('Invalid login credentials');
      }
    } catch (error) {
      console.log(error);
      console.log(responseData); 
      alert('Error occurred during login');
    }
  }
  
  return (
    <>
      <div className="signinDialog">
        <p className="sign" align="center">Sign in</p>
        <form className="form1" onSubmit={handleSubmit}>
        <input className="signinDialogInputs" type="text" align="center" placeholder="Username" value={Username} onChange={e => {
 
        setUsername(e.target.value);
}} />
          <input className="signinDialogInputs" type="password" align="center" placeholder="Password" value={Password} onChange={e => setPassword(e.target.value)} />
          <button className="submit" type="submit" align="center">Sign in</button>
         
        </form>
      </div>
    </>
  );
}

export default Login;
