import React, { useState, useEffect } from 'react';
import './App.css';
import { Link } from 'react-router-dom';
import AppRouter from './AppRouter';
import logo from './genee.png';
import {AuthProvider, AuthContext}  from './utils/AuthContext';
import {PrivateRoute} from '../src/utils/PrivateRoute';


function App() {

  const homepage ="http://www.genee-group.com";
  const [isAuthenticated, setIsAuthenticated] = useState(false);

 
  const login = () => {
    setIsAuthenticated(true);

  };

  useEffect(() => {
    if (isAuthenticated) {
      // Set session timeout to 1 minute (for testing purposes)
      const timeoutId = setTimeout(() => setIsAuthenticated(false), 60 * 60 * 1000);
      return () => clearTimeout(timeoutId); // Clean up the timeout on unmount or logout
    }
  }, [isAuthenticated]);

  return (
    <div className="App">
      <AuthProvider>
        <img id='logo' src={logo} href={homepage} />
        <div className='navbar'>
          <a href='/home'>Warranty Registration</a>
          <a href='/serial-checker'>Serial Checker</a>
          <a href='/bulk-upload'>Login</a>
        </div>
        <AppRouter />
      </AuthProvider>
      <footer>© 2023 GENEE GROUP. All Rights Reserved . <br/> VAT Registered: GB307530526</footer>
    </div>
  );
}


export default App;
