import React, { useState, useRef } from 'react';
import Papa from 'papaparse';
import axios from 'axios';
import { type } from '@testing-library/user-event/dist/type';

const BulkUpload = () => {

  const [csvfile, setCsvFile] = useState(undefined);
  let [parsedData, setParsedData] = useState([]);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const filesInputRef = useRef(null);

  const readFile = file => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = event => resolve(event.target.result);
      reader.onerror = error => reject(error);
      reader.readAsText(file);
      console.log(typeof(file));
    });
  };

  const handleChange = event => {
    const file = event.target.files[0];
    const fileType = file.type;
    const fileName = file.name;
    const fileExtension = fileName.split('.').pop().toLowerCase();
    if (fileType === 'text/csv' && fileExtension === 'csv') {
      setCsvFile(file);
      setError(false);
    } else {
      setCsvFile(undefined);
      setError(true);
    }
  };

  let importCSV = async () => {
    try {
      const csvData = await readFile(csvfile);
      const parsedData = Papa.parse(csvData, { header: true }).data;
      console.log(parsedData);
      const response = await axios.post('/bulk', { warranty: parsedData });
      console.log(response);
      setSuccess(true);
      return true;
      

    } catch (error) {
      console.log(error);
      return false;
    }
  };
  
  

  const parseCSV = () => {
    Papa.parse(csvfile, {
      header: true,
      complete: results => {
        console.log('Complete', results.data[0].ProductName, 'records.'); 
        
      }
    });
  };

  

  const updateData = result => {
    const data = result.data;
    setParsedData(data);
    console.log(data);
    // Do something with the parsed data
  };

  return (
    <div className="bulkUpload">
      <h2>Import CSV File</h2>
      <input
        className="csv-input"
        type="file"
        ref={filesInputRef}
        name="file"
        placeholder={null}
        onChange={handleChange}
      />
      {error && <p style={{ color: 'red' }}>Please upload a CSV file.</p>}
      {success && <p style={{ color: 'Green' }}>Successfully Uploaded</p>}
      <p />
      <button className='submit' disabled={!csvfile} onClick={parseCSV}>
        Parse CSV
      </button>
      <button   className='submit' disabled={!csvfile} onClick={() => importCSV(csvfile)}>
        Upload now!
      </button>
    </div>
  );
};

export default BulkUpload;

